export var END_POINTS_MODULES = Object.freeze({
    SUPERVISION: 'supervision',
    COGNITO_USER: 'cognito/user',
    CARE_INSIGHTS: 'careinsights',
    MEASUREMENT: 'measurement',
    COGNITO: 'cognito',
    PUSHER: '/pusher',
    USER: 'users',
});
var BASE_USER_QUERY = "".concat(END_POINTS_MODULES.USER, "/query");
var BASE_ADD_USER = "".concat(END_POINTS_MODULES.USER, "/add_user");
var BASE_SENIOR_END_POINT = "".concat(END_POINTS_MODULES.USER, "/senior");
var BASE_PROVIDER_END_POINT = "".concat(END_POINTS_MODULES.USER, "/provider");
var BASE_CONFIG_END_POINT = "".concat(END_POINTS_MODULES.USER, "/public-information");
var BASE_WELLNESS_SURVEY_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/wellness-survey");
var BASE_WELLNESS_PLAN_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/wellness-plan");
var BASE_SLEEP_END_POINT = "".concat(END_POINTS_MODULES.MEASUREMENT, "/sleep");
var BASE_ALARM_END_POINT = "".concat(END_POINTS_MODULES.MEASUREMENT, "/alarm");
var BASE_VITALS_END_POINT = "".concat(END_POINTS_MODULES.MEASUREMENT, "/vitals");
var BASE_BODY_HEALTH_END_POINT = "".concat(END_POINTS_MODULES.MEASUREMENT, "/body-health");
var BASE_HOLISTIC_ASSESSMENT_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/holistic-assessment");
var BASE_CAREGIVER_STRAIN_ASSESSMENT_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/caregiver-strain-assessment");
var BASE_LAWTON_BRODY_ADL_ASSESSMENT_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/adl-brody-assessment");
var BASE_KATZ_ADL_ASSESSMENT_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/adl-katz-assessment");
var BASE_HOLISTIC_ASSESSMENT_ADMIN_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/holistic-assessment-form-admin");
var BASE_UCLA_LONELINESS_ASSESSMENT_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/ucla-loneliness-assessment");
var BASE_UCLA_LONELINESS_ASSESSMENT_ADMIN_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/ucla-loneliness-assessment-form-admin");
var BASE_FALLSANDHOSPITALIZATION_ASSESSMENT_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/fall-hospitalization-assessment");
var BASE_FALLSANDHOSPITALIZATION_ASSESSMENT_ADMIN_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/fall-hospitalization-assessment-form-admin");
var BASE_HOMESAFETY_ASSESSMENT_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/home-safety-assessment");
var BASE_HOMESAFETY_ASSESSMENT_ADMIN_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/home-safety-assessment-form-admin");
var BASE_MEMBER_QUESTIONNAIRE_2_MEMBER_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/member_questionnaire");
var BASE_MEMBER_QUESTIONNAIRE_1_MEMBER_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/member_questionnaire");
var BASE_MEMBER_QUESTIONNAIRE_3_MEMBER_END_POINT = "".concat(END_POINTS_MODULES.SUPERVISION, "/member_questionnaire");
export var WELLNESS_SURVEY_END_POINTS = Object.freeze({
    FETCH: "".concat(BASE_WELLNESS_SURVEY_END_POINT, "/fetch"),
    POST: "".concat(BASE_WELLNESS_SURVEY_END_POINT),
});
export var USER_END_POINTS = Object.freeze({
    IS_EMAIL_MAPPED: "".concat(END_POINTS_MODULES.COGNITO_USER, "/is-email-mapped"),
    IS_MOBILE_MAPPED: "".concat(END_POINTS_MODULES.COGNITO_USER, "/is-mobile-mapped"),
    GENERATE_MEMBERID: "".concat(END_POINTS_MODULES.COGNITO_USER, "/generate-memberid"),
});
export var THRESHOLD_END_POINTS = Object.freeze({
    GET_VITALS: "".concat(END_POINTS_MODULES.CARE_INSIGHTS, "/fetch-vitals"),
    UPDATE_VITAL_STATE: "".concat(END_POINTS_MODULES.CARE_INSIGHTS, "/update_state"),
    UPDATE_THRESHOLD_CONFIG: "".concat(END_POINTS_MODULES.CARE_INSIGHTS, "/update_threshold"),
    DOWNLOAD_DETAILED_DATA: "".concat(END_POINTS_MODULES.CARE_INSIGHTS, "/summary-report"),
    MEASUREMENT: END_POINTS_MODULES.MEASUREMENT,
    GET_THRESHOLD: "".concat(END_POINTS_MODULES.CARE_INSIGHTS, "/get-threshold"),
});
export var CARE_INSIGHT_END_POINTS = Object.freeze({
    GET_CARE_INSIGHT_HISTORY: "".concat(END_POINTS_MODULES.CARE_INSIGHTS, "/care-insight"),
    GET_CARE_INSIGHT_TRANSACTION: "".concat(END_POINTS_MODULES.CARE_INSIGHTS, "/insight-history"),
    POST_CARE_INSIGHT_SUMMARY: "".concat(END_POINTS_MODULES.CARE_INSIGHTS, "/summary-recap"),
    UPDATE_CARE_INSIGHT_STATUS: "".concat(END_POINTS_MODULES.CARE_INSIGHTS, "/insight-status"),
    GET_ALL_CARE_INSIGHT: "".concat(END_POINTS_MODULES.CARE_INSIGHTS, "/all-care-insight"),
    GET_ALL_FACILITY_CARE_INSIGHT: "".concat(END_POINTS_MODULES.CARE_INSIGHTS, "/all-facility-care-insight"),
    POST_CARE_INSIGHT_ALERT: "".concat(END_POINTS_MODULES.CARE_INSIGHTS, "/action-update"),
    GET_CI_RANGE_MILESTONES: "".concat(END_POINTS_MODULES.CARE_INSIGHTS, "/get-ci-range-milestone"),
    PUT_UPDATE_CARE_INSIGHT: "".concat(END_POINTS_MODULES.CARE_INSIGHTS, "/update-insight"),
});
export var PUSHER_END_POINTS = Object.freeze({
    AUTH: "".concat(END_POINTS_MODULES.COGNITO).concat(END_POINTS_MODULES.PUSHER, "/auth"),
});
export var SLEEP_END_POINTS = Object.freeze({
    GET_SLEEP_DAILY: "".concat(BASE_SLEEP_END_POINT, "/sleep-daily"),
    GET_SLEEP_HEART_RATE: "".concat(BASE_SLEEP_END_POINT, "/sleep-hr"),
    GET_SLEEP_PHASE: "".concat(BASE_SLEEP_END_POINT, "/sleep-phase"),
    GET_SLEEP_DEPTH: "".concat(BASE_SLEEP_END_POINT, "/sleep-depth"),
});
export var LOCATION_END_POINTS = Object.freeze({
    GET_LOCATION: "".concat(BASE_ALARM_END_POINT, "/location"),
    GET_ENABLE_DATES: "".concat(BASE_ALARM_END_POINT, "/get-location-dates"),
    POST_LOCATION: "".concat(BASE_SENIOR_END_POINT, "/locate-me"),
});
export var USER_SERVICE_ENDPOINTS = Object.freeze({
    GET_CARE_AGENT: "".concat(BASE_USER_QUERY, "/careagent-list"),
    GET_CARE_AGENT_ADMIN: "".concat(BASE_USER_QUERY, "/careagent"),
    ADD_CARE_AGENT: "".concat(BASE_ADD_USER, "/minimal-info"),
    DISABLE_CARE_AGENT: "".concat(END_POINTS_MODULES.USER, "/toggle_user_state"),
    RESEND_OTP: "".concat(END_POINTS_MODULES.COGNITO, "/resent_otp"),
    FORGOT_PASSWORD: "".concat(END_POINTS_MODULES.COGNITO, "/forgot_password"),
    RESET_PASSWORD: "".concat(END_POINTS_MODULES.COGNITO, "/authentication/reset-password"),
    VALIDATE_PASSWORD: "".concat(END_POINTS_MODULES.COGNITO, "/authentication/password-validate"),
    CHANGE_PASSWORD: "".concat(END_POINTS_MODULES.COGNITO, "/authentication/change-password"),
    GET_USER_MAPPING: "".concat(BASE_USER_QUERY, "/user-info"),
    GET_SENIOR_LIST_ADMIN: "".concat(BASE_USER_QUERY, "/get-senior-list"),
    DISABLE_SENIOR_CW: "".concat(BASE_USER_QUERY, "/disable-senior-reason"),
});
export var SENIOR_SERVICE_END_POINTS = Object.freeze({
    GET_CARE_GIVER: "".concat(BASE_SENIOR_END_POINT, "/care-circle"),
    GET_MINIMAL_INFO: "".concat(BASE_SENIOR_END_POINT, "/minimal-info"),
    GET_BASIC_INFO: "".concat(BASE_SENIOR_END_POINT, "/basic-info"),
    GET_DOCTOR_INFO: "".concat(BASE_PROVIDER_END_POINT, "/doctor"),
    GET_DENTIST_INFO: "".concat(BASE_PROVIDER_END_POINT, "/dentist"),
    GET_PHARMACY_INFO: "".concat(BASE_PROVIDER_END_POINT, "/pharmacy"),
    GET_SENIOR_LIST: "".concat(BASE_SENIOR_END_POINT, "/list"),
    GET_MULTIPLE_IMAGES: "".concat(BASE_SENIOR_END_POINT, "/multiple-images"),
    GET_SENIOR_MAPPING: "".concat(BASE_USER_QUERY, "/senior-info"),
    POST_MINIMAL_INFO: "".concat(BASE_SENIOR_END_POINT, "/minimal-info"),
    GET_BDM: "".concat(BASE_USER_QUERY, "/senior-list-bdm"),
});
export var WELLESS_PLAN_END_POINTS = Object.freeze({
    GET_WELLNESS_PLAN: "".concat(BASE_WELLNESS_PLAN_END_POINT, "/fetch"),
    UPDATE_WELLNESS_PLAN: "".concat(BASE_WELLNESS_PLAN_END_POINT),
});
export var HOLISTIC_ASSESSMENT_END_POINTS = Object.freeze({
    GET_HOLISTIC_ASSESSMENT: "".concat(BASE_HOLISTIC_ASSESSMENT_END_POINT),
    POST_HOLISTIC_ASSESSMENT: "".concat(BASE_HOLISTIC_ASSESSMENT_END_POINT, "/add"),
    GET_BLANK_HOLISTIC_ASSESSMENT: "".concat(END_POINTS_MODULES.SUPERVISION, "/holistic-assessment-form"),
    GET_HISTORY: "".concat(END_POINTS_MODULES.SUPERVISION, "/holistic-assessment-history"),
    GET_HOLISTIC_ASSESSMENT_ADMIN: "".concat(BASE_HOLISTIC_ASSESSMENT_ADMIN_END_POINT, "/get"),
    POST_HOLISTIC_ASSESSMENT_ADMIN: "".concat(BASE_HOLISTIC_ASSESSMENT_ADMIN_END_POINT, "/add"),
    GET_HOLISTIC_ASSESSMENT_HISTORY_ADMIN: "".concat(BASE_HOLISTIC_ASSESSMENT_ADMIN_END_POINT, "/history"),
});
export var CAREGIVER_STRAIN_ASSESSMENT_END_POINTS = Object.freeze({
    GET_CAREGIVER_STRAIN_ASSESSMENT: "".concat(BASE_CAREGIVER_STRAIN_ASSESSMENT_END_POINT, "/get"),
    POST_CAREGIVER_STRAIN_ASSESSMENT: "".concat(BASE_CAREGIVER_STRAIN_ASSESSMENT_END_POINT, "/add"),
    GET_BLANK_CAREGIVER_STRAIN_ASSESSMENT: "".concat(BASE_CAREGIVER_STRAIN_ASSESSMENT_END_POINT, "/form"),
    GET_HISTORY: "".concat(BASE_CAREGIVER_STRAIN_ASSESSMENT_END_POINT, "/history"),
});
export var LAWTON_BRODY_ADL_ASSESSMENT_END_POINTS = Object.freeze({
    GET_LAWTON_BRODY_ADL_ASSESSMENT: "".concat(BASE_LAWTON_BRODY_ADL_ASSESSMENT_END_POINT, "/get"),
    POST_LAWTON_BRODY_ADL_ASSESSMENT: "".concat(BASE_LAWTON_BRODY_ADL_ASSESSMENT_END_POINT, "/add"),
    GET_BLANK_LAWTON_BRODY_ADL_ASSESSMENT: "".concat(BASE_LAWTON_BRODY_ADL_ASSESSMENT_END_POINT, "/form"),
    GET_LAWTON_BRODY_HISTORY: "".concat(BASE_LAWTON_BRODY_ADL_ASSESSMENT_END_POINT, "/history"),
});
export var KATZ_ADL_ASSESSMENT_END_POINTS = Object.freeze({
    GET_KATZ_ADL_ASSESSMENT: "".concat(BASE_KATZ_ADL_ASSESSMENT_END_POINT, "/get"),
    POST_KATZ_ADL_ASSESSMENT: "".concat(BASE_KATZ_ADL_ASSESSMENT_END_POINT, "/add"),
    GET_BLANK_KATZ_ADL_ASSESSMENT: "".concat(BASE_KATZ_ADL_ASSESSMENT_END_POINT, "/form"),
    GET_KATZ_HISTORY: "".concat(BASE_KATZ_ADL_ASSESSMENT_END_POINT, "/history"),
});
export var MEDICAL_CONDITION_END_POINTS = Object.freeze({
    GET_MEDICAL_CONDITION: "".concat(END_POINTS_MODULES.USER, "/medical-condition"),
    POST_MEDICAL_CONDITION: "".concat(END_POINTS_MODULES.USER, "/medical-condition"),
    GET_MEDICAL_CONDITION_HISTORY: "".concat(END_POINTS_MODULES.USER, "/medical-condition-history"),
    SEARCH_MEDICAL_CONDITION: "".concat(END_POINTS_MODULES.USER, "/get-medical-condition"),
});
export var MEDICATION_LIST_END_POINTS = Object.freeze({
    MEDICATION_LIST: "".concat(END_POINTS_MODULES.USER, "/user-medication"),
    SEARCH_MEDICATION: "".concat(END_POINTS_MODULES.USER, "/get-medication"),
});
export var CORPORATE_MANAGEMENT_END_POINTS = Object.freeze({
    POST_CORPORATE: "".concat(END_POINTS_MODULES.USER, "/corporation"),
    GET_CORPORATE_LIST: "".concat(END_POINTS_MODULES.USER, "/list-corporation"),
    SEARCH_CORPORATE: "".concat(END_POINTS_MODULES.USER, "/get-corporate-management"),
    ADD_CORPORATE: "".concat(END_POINTS_MODULES.USER, "/add-corporate"),
    DISABLE_CORPORATE: "".concat(END_POINTS_MODULES.USER, "/disable-corporate"),
    VALIDATE_DUPLICATE_DATA: "".concat(END_POINTS_MODULES.USER, "/validate-field"),
});
export var FACILITY_MANAGEMENT_END_POINTS = Object.freeze({
    GET_FACILITY_LIST: "".concat(END_POINTS_MODULES.USER, "/list-facility"),
    POST_FACILITY: "".concat(END_POINTS_MODULES.USER, "/facility"),
    SEARCH_FACILITY: "".concat(END_POINTS_MODULES.USER, "/get-facility-management"),
    ADD_FACILITY: "".concat(END_POINTS_MODULES.USER, "/add-facility"),
    DISABLE_FACILITY: "".concat(END_POINTS_MODULES.USER, "/facility"),
    VALIDATE_DUPLICATE: "".concat(END_POINTS_MODULES.USER, "/validate-facility-field"),
    CORP_FACILITY_USERS: "".concat(END_POINTS_MODULES.USER, "/list-agent"),
    DISABLE_FACILITY_AGENT_ACCOUNT: "".concat(END_POINTS_MODULES.USER, "/facility-agent/disable"),
});
export var SUGAR_CORPORATION_END_POINTS = Object.freeze({
    GET_CORPORATION_LIST: "".concat(END_POINTS_MODULES.USER, "/list-sugar-corporation"),
});
export var SUGAR_FACILITY_END_POINTS = Object.freeze({
    GET_FACILITY_LIST: "".concat(END_POINTS_MODULES.USER, "/list-sugar-facility"),
});
export var GOALS_END_POINTS = Object.freeze({
    GET_GOALS_DATA: "".concat(END_POINTS_MODULES.SUPERVISION, "/goal-action-data/fetch"),
    POST_GOALS_DATA: "".concat(END_POINTS_MODULES.SUPERVISION, "/goal-action-data"),
    GET_GOALS_ACTION_MAP_DATA: "".concat(END_POINTS_MODULES.SUPERVISION, "/goal-action/fetch"),
    POST_GOAL_NOTES: "".concat(END_POINTS_MODULES.SUPERVISION, "/goal-action-note-list"),
    POST_ADD_GOAL_NOTES: "".concat(END_POINTS_MODULES.SUPERVISION, "/goal-action-note/add"),
});
export var ALARM_END_POINTS = Object.freeze({
    GET_ALARMS: "".concat(BASE_ALARM_END_POINT, "/get-alarms"),
    UPDATE_ALARMS: "".concat(BASE_ALARM_END_POINT, "/update-alarm-status"),
    GET_SOS: "".concat(BASE_ALARM_END_POINT, "/sos"),
    GET_FALL_DETECTION: "".concat(BASE_ALARM_END_POINT, "/fall-detect"),
});
export var DEVICES_END_POINTS = Object.freeze({
    GET_DEVICES: "".concat(END_POINTS_MODULES.USER, "/medical-device"),
    GET_DEVICES_INFORMATION: "".concat(END_POINTS_MODULES.USER, "/get-device-info"),
});
var resourceUrl = "".concat(END_POINTS_MODULES.SUPERVISION, "/resource");
export var RESOURCES_END_POINTS = Object.freeze({
    GET_SIGNED_URL: resourceUrl,
    DELETE_FILES_URL: resourceUrl,
    POST_SIGNED_URL: resourceUrl,
});
export var CONFIG_END_POINTS = Object.freeze({
    GET_CONFIG_DATA: "".concat(BASE_CONFIG_END_POINT, "/message"),
    GET_SECRET_DATA: "".concat(END_POINTS_MODULES.COGNITO, "/client-configuration"),
});
export var SENIOR_COACH_MAPPING_ENDPOINTS = Object.freeze({
    POST_ASSIGN: "".concat(END_POINTS_MODULES.USER, "/senior-coach-map"),
    DELETE_UNASSIGN: "".concat(END_POINTS_MODULES.USER, "/senior-coach-map"),
});
export var ASSESSMENT_TAGS_ENDPOINTS = Object.freeze({
    GET_SUPERVISION_ASSESSMENT_TAGS: "".concat(END_POINTS_MODULES.SUPERVISION, "/assessment-tags"),
    GET_USER_ASSESSMENT_TAGS: "".concat(END_POINTS_MODULES.USER, "/assessment-tags"),
});
export var ACTIVITY_GOAL_ENDPOINTS = Object.freeze({
    GET_ACTIVITY_GOAL: "".concat(BASE_VITALS_END_POINT, "/activity-goal"),
    POST_ACTIVITY_GOAL: "".concat(BASE_VITALS_END_POINT, "/activity-goal"),
});
export var SLEEP_GOAL_ENDPOINTS = Object.freeze({
    GET_SLEEP_GOAL: "".concat(BASE_SLEEP_END_POINT, "/sleep-goal"),
    POST_SLEEP_GOAL: "".concat(BASE_SLEEP_END_POINT, "/sleep-goal"),
});
export var BODY_HEALTH_GOAL_ENDPOINTS = Object.freeze({
    GET_BODY_HEALTH_GOAL: "".concat(BASE_BODY_HEALTH_END_POINT, "/body-health-goal"),
    POST_BODY_HEALTH_GOAL: "".concat(BASE_BODY_HEALTH_END_POINT, "/body-health-goal"),
});
export var PUBLIC_APIS = Object.freeze({
    LOGIN: "".concat(END_POINTS_MODULES.COGNITO, "/authentication/login"),
    FORGOT_PASSWORD: USER_SERVICE_ENDPOINTS.FORGOT_PASSWORD,
    VALIDATE_PASSWORD: USER_SERVICE_ENDPOINTS.VALIDATE_PASSWORD,
    CHANGE_PASSWORD: USER_SERVICE_ENDPOINTS.CHANGE_PASSWORD,
    RESET_PASSWORD: USER_SERVICE_ENDPOINTS.RESET_PASSWORD,
});
export var FACILITY_GRAPH_APIS = Object.freeze({
    GET_ACTIVITY_GRAPH: "".concat(BASE_VITALS_END_POINT, "/facility-activity-info"),
    GET_FALL_GRAPH: "".concat(BASE_ALARM_END_POINT, "/facility-fall-info"),
    GET_LOS_GRAPH: "".concat(END_POINTS_MODULES.USER, "/facility-los-info"),
    // GET_HOLISTIC_GRAPH: `${END_POINTS_MODULES.SUPERVISION}/facility-holistic-info`,
    GET_WELLBEING_GRAPH: "".concat(END_POINTS_MODULES.SUPERVISION, "/facility-wellness-info"),
    GET_RESIDENT_COUNT: "".concat(END_POINTS_MODULES.USER, "/facility-residents-count"),
    GET_UCLA_LONELINESS_GRAPH: "".concat(END_POINTS_MODULES.SUPERVISION, "/facility-ucla-loneliness-info"),
});
export var REPORTS_ENDPOINTS = Object.freeze({
    POST_REPORT: "".concat(END_POINTS_MODULES.SUPERVISION, "/facility-report"),
    GET_REPORT: "".concat(END_POINTS_MODULES.SUPERVISION, "/facility-report"),
    GET_DOWNLOAD_REPORT: "".concat(END_POINTS_MODULES.SUPERVISION, "/download-facility-report"),
});
export var RELEASE_ENDPOINTS = Object.freeze({
    POST_RELEASE: "".concat(END_POINTS_MODULES.USER, "/public-information/global-message"),
    GET_RELEASE: "".concat(END_POINTS_MODULES.USER, "/public-information/global-message"),
});
export var UCLA_LONELINESS_ASSESSMENT_END_POINTS = Object.freeze({
    GET_UCLA_LONELINESS_ASSESSMENT: "".concat(BASE_UCLA_LONELINESS_ASSESSMENT_END_POINT),
    POST_UCLA_LONELINESS_ASSESSMENT: "".concat(BASE_UCLA_LONELINESS_ASSESSMENT_END_POINT, "/add"),
    GET_BLANK_UCLA_LONELINESS_ASSESSMENT: "".concat(END_POINTS_MODULES.SUPERVISION, "/ucla-loneliness-assessment-form"),
    GET_UCLA_LONELINESS_HISTORY: "".concat(END_POINTS_MODULES.SUPERVISION, "/ucla-lonliness-assessment-history"),
    GET_UCLA_LONELINESS_ASSESSMENT_ADMIN: "".concat(BASE_UCLA_LONELINESS_ASSESSMENT_ADMIN_END_POINT, "/get"),
    POST_UCLA_LONELINESS_ASSESSMENT_ADMIN: "".concat(BASE_UCLA_LONELINESS_ASSESSMENT_ADMIN_END_POINT, "/add"),
    GET_UCLA_LONELINESS_ASSESSMENT_HISTORY_ADMIN: "".concat(BASE_UCLA_LONELINESS_ASSESSMENT_ADMIN_END_POINT, "/history"),
});
export var FALLSANDHOSPITALIZATION_ASSESSMENT_END_POINTS = Object.freeze({
    GET_FALLSANDHOSPITALIZATION_ASSESSMENT: "".concat(BASE_FALLSANDHOSPITALIZATION_ASSESSMENT_END_POINT),
    POST_FALLSANDHOSPITALIZATION_ASSESSMENT: "".concat(BASE_FALLSANDHOSPITALIZATION_ASSESSMENT_END_POINT, "/add"),
    GET_BLANK_FALLSANDHOSPITALIZATION_ASSESSMENT: "".concat(END_POINTS_MODULES.SUPERVISION, "/fall-hospitalization-assessment-form"),
    GET_FALLSANDHOSPITALIZATION_HISTORY: "".concat(END_POINTS_MODULES.SUPERVISION, "/fall-hospitalization-assessment-history"),
    GET_FALLSANDHOSPITALIZATION_ASSESSMENT_ADMIN: "".concat(BASE_FALLSANDHOSPITALIZATION_ASSESSMENT_ADMIN_END_POINT, "/get"),
    POST_FALLSANDHOSPITALIZATION_ASSESSMENT_ADMIN: "".concat(BASE_FALLSANDHOSPITALIZATION_ASSESSMENT_ADMIN_END_POINT, "/add"),
    GET_FALLSANDHOSPITALIZATION_ASSESSMENT_HISTORY_ADMIN: "".concat(BASE_FALLSANDHOSPITALIZATION_ASSESSMENT_ADMIN_END_POINT, "/history"),
});
export var HOMESAFETY_ASSESSMENT_END_POINTS = Object.freeze({
    GET_HOMESAFETY_ASSESSMENT: "".concat(BASE_HOMESAFETY_ASSESSMENT_END_POINT),
    POST_HOMESAFETY_ASSESSMENT: "".concat(BASE_HOMESAFETY_ASSESSMENT_END_POINT, "/add"),
    GET_BLANK_HOMESAFETY_ASSESSMENT: "".concat(END_POINTS_MODULES.SUPERVISION, "/home-safety-assessment-form"),
    GET_HOMESAFETY_ASSESSMENT_HISTORY: "".concat(END_POINTS_MODULES.SUPERVISION, "/home-safety-assessment-history"),
    GET_HOMESAFETY_ASSESSMENT_ADMIN: "".concat(BASE_HOMESAFETY_ASSESSMENT_ADMIN_END_POINT, "/get"),
    POST_HOMESAFETY_ASSESSMENT_ADMIN: "".concat(BASE_HOMESAFETY_ASSESSMENT_ADMIN_END_POINT, "/add"),
    GET_HOMESAFETY_ASSESSMENT_HISTORY_ADMIN: "".concat(BASE_HOMESAFETY_ASSESSMENT_ADMIN_END_POINT, "/history"),
});
export var MEMBER_QUESTIONNAIRE_2_MEMBER_END_POINTS = Object.freeze({
    GET_MEMBER_QUESTIONNAIRE_2_MEMBER: "".concat(BASE_MEMBER_QUESTIONNAIRE_2_MEMBER_END_POINT, "/get"),
    POST_MEMBER_QUESTIONNAIRE_2_MEMBER: "".concat(BASE_MEMBER_QUESTIONNAIRE_2_MEMBER_END_POINT, "/add"),
    GET_BLANK_MEMBER_QUESTIONNAIRE_2_MEMBER: "".concat(BASE_MEMBER_QUESTIONNAIRE_2_MEMBER_END_POINT, "/form"),
    // GET_MEMBER_QUESTIONNAIRE_2_MEMBER_HISTORY: `${BASE_MEMBER_QUESTIONNAIRE_2_MEMBER_END_POINT}/history`,
});
export var MEMBER_QUESTIONNAIRE_1_MEMBER_END_POINTS = Object.freeze({
    GET_MEMBER_QUESTIONNAIRE_1_MEMBER: "".concat(BASE_MEMBER_QUESTIONNAIRE_1_MEMBER_END_POINT, "/get"),
    POST_MEMBER_QUESTIONNAIRE_1_MEMBER: "".concat(BASE_MEMBER_QUESTIONNAIRE_1_MEMBER_END_POINT, "/add"),
    GET_BLANK_MEMBER_QUESTIONNAIRE_1_MEMBER: "".concat(BASE_MEMBER_QUESTIONNAIRE_1_MEMBER_END_POINT, "/form"),
    // GET_MEMBER_QUESTIONNAIRE_1_MEMBER_HISTORY: `${BASE_MEMBER_QUESTIONNAIRE_1_MEMBER_END_POINT}/history`,
});
export var MEMBER_QUESTIONNAIRE_3_MEMBER_END_POINTS = Object.freeze({
    GET_MEMBER_QUESTIONNAIRE_3_MEMBER: "".concat(BASE_MEMBER_QUESTIONNAIRE_3_MEMBER_END_POINT, "/get"),
    POST_MEMBER_QUESTIONNAIRE_3_MEMBER: "".concat(BASE_MEMBER_QUESTIONNAIRE_3_MEMBER_END_POINT, "/add"),
    GET_BLANK_MEMBER_QUESTIONNAIRE_3_MEMBER: "".concat(BASE_MEMBER_QUESTIONNAIRE_3_MEMBER_END_POINT, "/form"),
    // GET_MEMBER_QUESTIONNAIRE_3_MEMBER_HISTORY: `${BASE_MEMBER_QUESTIONNAIRE_3_MEMBER_END_POINT}/history`,
});
