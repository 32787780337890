var _a;
import { API_RESPONSE_REPORT_TYPES, CareInsightTypes } from './enums';
export var CALL_TYPES = [
    {
        key: 'Senior Onboarding',
        value: 'Senior Onboarding',
    },
    {
        key: 'Caregiver Onboarding',
        value: 'Caregiver Onboarding',
    },
    {
        key: 'Senior Check-in',
        value: 'Senior Check-in',
    },
    {
        key: 'Caregiver Check-in',
        value: 'Caregiver Check-in',
    },
    {
        key: 'Return Call',
        value: 'Return Call',
    },
    {
        key: 'Community follow-up',
        value: 'Community follow-up',
    },
    {
        key: 'Fall Detected',
        value: 'Fall Detected',
    },
    {
        key: 'SOS',
        value: 'SOS',
    },
    {
        key: 'Care Coordination',
        value: 'Care Coordination',
    },
    {
        key: 'Care Insight Notification',
        value: 'Care Insight Notification',
    },
    {
        key: 'Other',
        value: 'Other',
    },
];
export var TEN_SECONDS = 1000 * 10; //10 sec in milliseconds
export var IDLE_USER_TIMEOUT = 1000 * 60 * 60 * 24 * 5; //5 days in milliseconds
// export const IDLE_USER_TIMEOUT = 1000 * 60 * 10 //10min in milliseconds
export var DEFAULT_AUTO_SAVE_TIMEOUT = 1000 * 60; //60 seconds
export var LOCAL_STORAGE_KEY = {
    AUTO_SAVE_TIMER: 'autoSaveTimer',
};
export var CACHE_EVENTS_AUTO_LOGOUT = {
    REQUESTING_SESSION: 'REQUESTING_SESSION',
    SESSION_SHARING: 'SESSION_SHARING',
    SESSION_FLUSH: 'SESSION_FLUSH',
    RELOAD_SESSION: 'RELOAD_SESSION',
};
export var USER_INFO_KEY = 'userInfo';
export var USER_SESSION_KEY = 'isUser';
export var LOGOUT_MESSAGE = 'User logged out successfully!';
export var SESSION_EXPIRED_MESSAGE = 'User logged out due to session expired!';
export var AUTO_LOGOUT_MESSAGE = 'User logged out due to inactivity';
export var API_DATA_LIMIT = 100;
export var TABLE_ROWS_PER_PAGE = 20;
export var TABLE_CACHE_KEY = {
    CALL_LOGS: 'callLogs',
    WELLNESS_SURVEY: 'wellnessSurvey',
    GOALS: 'goals',
};
export var MEASUREMENT_TYPE = Object.freeze({
    WELLNESS_SURVEY: 'wellness_survey',
    SLEEP: 'sleep',
});
export var ERROR_STATUS = Object.freeze({
    UNAUTHORIZED: '401',
});
export var DATE_FORMAT = 'MM/DD/YYYY';
export var DATE_FORMAT_SHORT = 'MM/DD';
export var DATE_FORMAT_SHORT_YEAR = 'MM/DD/YY';
export var DATE_FORMAT_YEAR = 'YYYY-MM-DD';
//need to convert in object
export var TIME_FORMAT = 'hh:mm A';
export var DEVICE_TYPE = Object.freeze({
    WATCH: 'watch',
});
export var US_STATES_WITH_VALUE = [
    { value: 'AL', label: 'AL' },
    { value: 'AK', label: 'AK' },
    { value: 'AS', label: 'AS' },
    { value: 'AZ', label: 'AZ' },
    { value: 'AR', label: 'AR' },
    { value: 'CA', label: 'CA' },
    { value: 'CO', label: 'CO' },
    { value: 'CT', label: 'CT' },
    { value: 'DE', label: 'DE' },
    { value: 'DC', label: 'DC' },
    { value: 'FM', label: 'FM' },
    { value: 'FL', label: 'FL' },
    { value: 'GA', label: 'GA' },
    { value: 'GU', label: 'GU' },
    { value: 'HI', label: 'HI' },
    { value: 'ID', label: 'ID' },
    { value: 'IL', label: 'IL' },
    { value: 'IN', label: 'IN' },
    { value: 'IA', label: 'IA' },
    { value: 'KS', label: 'KS' },
    { value: 'KY', label: 'KY' },
    { value: 'LA', label: 'LA' },
    { value: 'ME', label: 'ME' },
    { value: 'MH', label: 'MH' },
    { value: 'MD', label: 'MD' },
    { value: 'MA', label: 'MA' },
    { value: 'MI', label: 'MI' },
    { value: 'MN', label: 'MN' },
    { value: 'MS', label: 'MS' },
    { value: 'MO', label: 'MO' },
    { value: 'MT', label: 'MT' },
    { value: 'NE', label: 'NE' },
    { value: 'NV', label: 'NV' },
    { value: 'NH', label: 'NH' },
    { value: 'NJ', label: 'NJ' },
    { value: 'NM', label: 'NM' },
    { value: 'NY', label: 'NY' },
    { value: 'NC', label: 'NC' },
    { value: 'ND', label: 'ND' },
    { value: 'MP', label: 'MP' },
    { value: 'OH', label: 'OH' },
    { value: 'OK', label: 'OK' },
    { value: 'OR', label: 'OR' },
    { value: 'PW', label: 'PW' },
    { value: 'PA', label: 'PA' },
    { value: 'PR', label: 'PR' },
    { value: 'RI', label: 'RI' },
    { value: 'SC', label: 'SC' },
    { value: 'SD', label: 'SD' },
    { value: 'TN', label: 'TN' },
    { value: 'TX', label: 'TX' },
    { value: 'UT', label: 'UT' },
    { value: 'VT', label: 'VT' },
    { value: 'VI', label: 'VI' },
    { value: 'VA', label: 'VA' },
    { value: 'WA', label: 'WA' },
    { value: 'WV', label: 'WV' },
    { value: 'WI', label: 'WI' },
    { value: 'WY', label: 'WY' },
];
export var US_STATES = [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
];
export var US_STATE_MAPPINGS = [
    { name: 'ALABAMA', abbreviation: 'AL' },
    { name: 'ALASKA', abbreviation: 'AK' },
    { name: 'AMERICAN SAMOA', abbreviation: 'AS' },
    { name: 'ARIZONA', abbreviation: 'AZ' },
    { name: 'ARKANSAS', abbreviation: 'AR' },
    { name: 'CALIFORNIA', abbreviation: 'CA' },
    { name: 'COLORADO', abbreviation: 'CO' },
    { name: 'CONNECTICUT', abbreviation: 'CT' },
    { name: 'DELAWARE', abbreviation: 'DE' },
    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
    { name: 'FLORIDA', abbreviation: 'FL' },
    { name: 'GEORGIA', abbreviation: 'GA' },
    { name: 'GUAM', abbreviation: 'GU' },
    { name: 'HAWAII', abbreviation: 'HI' },
    { name: 'IDAHO', abbreviation: 'ID' },
    { name: 'ILLINOIS', abbreviation: 'IL' },
    { name: 'INDIANA', abbreviation: 'IN' },
    { name: 'IOWA', abbreviation: 'IA' },
    { name: 'KANSAS', abbreviation: 'KS' },
    { name: 'KENTUCKY', abbreviation: 'KY' },
    { name: 'LOUISIANA', abbreviation: 'LA' },
    { name: 'MAINE', abbreviation: 'ME' },
    { name: 'MARYLAND', abbreviation: 'MD' },
    { name: 'MASSACHUSETTS', abbreviation: 'MA' },
    { name: 'MICHIGAN', abbreviation: 'MI' },
    { name: 'MINNESOTA', abbreviation: 'MN' },
    { name: 'MISSISSIPPI', abbreviation: 'MS' },
    { name: 'MISSOURI', abbreviation: 'MO' },
    { name: 'MONTANA', abbreviation: 'MT' },
    { name: 'NEBRASKA', abbreviation: 'NE' },
    { name: 'NEVADA', abbreviation: 'NV' },
    { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
    { name: 'NEW JERSEY', abbreviation: 'NJ' },
    { name: 'NEW MEXICO', abbreviation: 'NM' },
    { name: 'NEW YORK', abbreviation: 'NY' },
    { name: 'NORTH CAROLINA', abbreviation: 'NC' },
    { name: 'NORTH DAKOTA', abbreviation: 'ND' },
    { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
    { name: 'OHIO', abbreviation: 'OH' },
    { name: 'OKLAHOMA', abbreviation: 'OK' },
    { name: 'OREGON', abbreviation: 'OR' },
    { name: 'PENNSYLVANIA', abbreviation: 'PA' },
    { name: 'PUERTO RICO', abbreviation: 'PR' },
    { name: 'RHODE ISLAND', abbreviation: 'RI' },
    { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
    { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
    { name: 'TENNESSEE', abbreviation: 'TN' },
    { name: 'TEXAS', abbreviation: 'TX' },
    { name: 'UTAH', abbreviation: 'UT' },
    { name: 'VERMONT', abbreviation: 'VT' },
    { name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
    { name: 'VIRGINIA', abbreviation: 'VA' },
    { name: 'WASHINGTON', abbreviation: 'WA' },
    { name: 'WEST VIRGINIA', abbreviation: 'WV' },
    { name: 'WISCONSIN', abbreviation: 'WI' },
    { name: 'WYOMING', abbreviation: 'WY' },
];
export var DIALOG_TYPES;
(function (DIALOG_TYPES) {
    DIALOG_TYPES["LIMIT"] = "LIMIT";
    DIALOG_TYPES["MESSAGE_ACTION_DIALOG"] = "MESSAGE_ACTION_DIALOG";
    DIALOG_TYPES["DAILY_RECAP"] = "DAILY_RECAP";
    DIALOG_TYPES["ADD_AGENT"] = "ADD_AGENT";
    DIALOG_TYPES["ADMIN_ACTION_DIALOG"] = "ADMIN_ACTION_DIALOG";
    DIALOG_TYPES["SUCCESS"] = "SUCCESS";
    DIALOG_TYPES["ERROR"] = "ERROR";
    DIALOG_TYPES["ERROR2"] = "ERROR2";
    DIALOG_TYPES["CALL_ENTRY"] = "CALL_ENTRY";
    DIALOG_TYPES["RESOURCES"] = "RESOURCES";
    DIALOG_TYPES["MEDICATION"] = "MEDICATION";
    DIALOG_TYPES["ASSIGN_WELLNESS_COACH"] = "ASSIGN_WELLNESS_COACH";
    DIALOG_TYPES["UNASSIGN_SENIORS"] = "UNASSIGN_SENIORS";
    DIALOG_TYPES["CORPORATE"] = "CORPORATE";
    DIALOG_TYPES["FACILITY"] = "FACILITY";
    DIALOG_TYPES["FACILITY_USER"] = "FACILITY_USER";
    DIALOG_TYPES["DISPLAY_MESSAGE"] = "DISPLAY_MESSAGE";
    DIALOG_TYPES["CLEARWELLNESS_ACCOUNT"] = "CLEARWELLNESS_ACCOUNT";
    DIALOG_TYPES["CLEARWELLNESS_REASON"] = "CLEARWELLNESS_REASON";
    DIALOG_TYPES["SENIORLIST_REVIEW"] = "SENIORLIST_REVIEW";
    DIALOG_TYPES["GOAL_NOTES"] = "GOAL_NOTES";
    DIALOG_TYPES["RELEASE"] = "RELEASE";
})(DIALOG_TYPES || (DIALOG_TYPES = {}));
export var IMAGE_LIMIT_EXCEED_MESSAGE = "You cannot select more than 10 senior's photos.";
export var IMAGE_TYPES = ['image/png', 'image/jpeg'];
export var INVALID_IMAGE_FORMAT = 'Invalid image format.';
export var INVALID_DATE = 'Invalid Date';
export var INVALID_TIME = 'Invalid Time';
export var DATE_ERROR_MESSAGE = {
    maxDateMessage: '“From” date cannot be greater than “To” date',
    minDateMessage: '“To” date cannot be less than “From” date',
    futureDateDisable: 'Date cannot be greater than today',
    beforeOnboarding: 'Date should not be before onboarding date',
    goals: {
        startDate: 'Date cannot be greater than “Target date”',
        targetDate: 'Date cannot be less than “Start date”',
    },
    emailIdAlreadyExist: 'Email id is already in use',
    mobileNumberAlreadyExist: 'Mobile Number is already in use with another caregiver',
};
export var REGEX = Object.freeze({
    EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    ONLY_NUMBER_ONE_DEC: /^(\d)+(\.)?([0-9]{1})?$/gim,
    URL: /^https?:\/\//i,
    BLANK_FIELD: /^(?=.*\S)/g,
    ONLY_ALPHABETS: /^\s*[a-zA-Z]+\s*$/,
    APARTMENT: /^[a-zA-Z0-9 ]+$/,
    WITHINGS_HUBS_SR_NO: /^[a-zA-Z0-9]+$/
});
export var FORM_ERROR_MESSAGES = {
    blankFieldErrorMessage: 'Required Field',
    InvalidErrorMessage: 'Invalid Apartment Number',
    InvalidErrorMessageSerialNo: 'Invalid Serial Number'
};
export var EXISTING_APP_VITALS = [
    'body_weight',
    'heart_rate_measurement',
    'body_hydration',
    'sleep_score',
    'sleep_duration',
    'sleep_depth',
    'activity_measurement',
    'activity_score',
];
//FE pagination limit
export var PAGINATION_LIMIT = {
    messageManager: 15,
    adminAgentAccount: 15,
    adminCareInsightReview: 15,
    goals: 6,
    assessmentHistory: 6,
    adminCIRangeMilestones: 10,
    homeSenior: 100,
    medication: 5,
    seniorCoachAssignment: 15,
    wellnessCoachUnassignList: 14,
    wellnessCoachList: 9,
    medicalCondition: 10,
    corporateAndFacilities: 10,
    wellbiengSurvey: 20,
    corpFacilityUsers: 15,
    reports: 20,
};
//Record API limit
export var FETCH_LIMIT = {
    medicalCondition: 100,
    facilityAutoCompleteLimit: 200,
    corporateAndFacilities: 100,
    corpFacilityUsers: 100,
};
export var CHARACTERS_LIMIT = {
    searchMedication: 25,
};
export var MAX_RECORDS_LIMIT = {
    medication: 15,
};
export var APPLICATION_EVENTS = Object.freeze({
    summary: {
        label: 'Summary',
        summarMessageCharCount: 2000,
    },
    alert: {
        label: 'Alert',
        alertMessageCharCount: 2000,
    },
    sos: {
        label: 'SOS',
        shortLabel: 'SOS',
    },
    fall: {
        label: 'Fall Detection',
        shortLabel: 'Fall',
    },
    call_entry: {
        label: 'Call Entry',
    },
    medicalAssessment: {
        medicalAssessmentNotesCharCount: 500,
    },
    wellnessSurvey: {
        wellnessSurveyNotesCharCount: 500,
    },
    medication: {
        medicationCharCount: 500,
    },
});
export var TOAST_MESSAGES = Object.freeze({
    POST_CARE_INSIGHT_SUMMARY: {
        SUCCESS: 'Summary message submitted successfully.',
        FAIL: 'Failed to submit summary message.',
    },
    GET_ALL_CARE_INSIGHT: {
        FAIL: 'Failed to fetch exisisting events data.',
    },
});
export var LOCATION = {
    RADIUS_MEASUREMENT: {
        FEET: 'feet',
        MILE: 'mile',
    },
};
export var EVENT_NODE_NAME = {
    HTML: 'HTML',
};
export var DEFAULT_CARE_AGENT_DATA = {
    email: '',
    accessToken: '',
    refreshToken: '',
    userName: { middle_name: '', first_name: '', last_name: '' },
    userId: '',
    userRole: [],
};
export var ERROR_MESSAGE = {
    REQUIRED_FIELD: 'Required Field',
    INVALID_EMAIL: 'Invalid email address',
    SEARCH_ERROR: 'Results could not be fetched. Please try again',
};
export var timezoneAbbrsFullname = {
    GMT: 'Greenwich Mean Time',
    UTC: 'Universal Coordinated Time',
    ECT: 'European Central Time',
    EET: 'Eastern European Time',
    ART: '(Arabic) Egypt Standard Time',
    EAT: 'Eastern African Time',
    MET: 'Middle East Time',
    NET: 'Near East Time',
    PKT: 'Pakistan Standard Time',
    IST: 'Indian Standard Time',
    BST: 'Bangladesh Standard Time',
    VST: 'Vietnam Standard Time',
    CTT: 'China Taiwan Time',
    JST: 'Japan Standard Time',
    ACT: 'Australia Central Time',
    AET: 'Australia Eastern Time',
    SST: 'Solomon Standard Time',
    NST: 'New Zealand Standard Time',
    MIT: 'Midway Islands Time',
    HST: 'Hawaii Standard Time',
    AST: 'Alaska Standard Time',
    PST: 'Pacific Standard Time',
    PNT: 'Phoenix Standard Time',
    MST: 'Mountain Standard Time',
    CST: 'Central Standard Time',
    EST: 'Eastern Standard Time',
    IET: 'Indiana Eastern Standard Time',
    PRT: 'Puerto Rico and US Virgin Islands Time',
    CNT: 'Canada Newfoundland Time',
    AGT: 'Argentina Standard Time',
    BET: 'Brazil Eastern Time',
    CAT: 'Central African Time',
};
export var LocationLocateMeError = {
    locationNotFetched: 'Location could not be fetched, please try again.',
    deviceNotReachable: 'Device not reachable, please try later.',
};
export var ZINDEX = {
    ACTION_ERROR: 9999,
    SOS: 5000,
    FALL: 4000,
    CALL_ENTRY: 3000,
    ALERT: 2000,
    SUMMARY: 1000,
};
export var AdminNotificationsLabels = Object.freeze({
    alert: 'You have a new Care insight Alert',
    summary: 'You have a new Care insight Summary',
    milestone: 'You have a new CI Range Milestone',
});
export var SEARCH_MINIMUM_CHAR_COUNT = 2;
export var delayInMilliSeconds = {
    SOS_FALL: 1300,
};
export var intervalInSeconds = {
    SOS_FALL: 120,
};
export var CGAssessmentResponseValues = {
    No: 0,
    Sometimes: 1,
    Regular: 2,
};
export var assessmentScore = {
    Selected: 1,
    NotSelected: 0,
};
export var ALERT_MESSAGES = Object.freeze({
    autoSaveLogout: 'Changes that you made are not saved. Please save the form before logging out.',
});
export var resourcesConfig = {
    MAX_TOTAL_COUNT: 10,
    MAX_URL_COUNT: 10,
    FILE_SIZE_IN_MB: 15,
};
export var activityGoalOptions = new Array(10)
    .fill(0)
    .map(function (v, i) { return ({
    label: "".concat((i + 1) * 5000),
    value: (i + 1) * 5000,
}); });
export var summaryTypeLabel = (_a = {},
    _a[CareInsightTypes.Summary] = 'Caregiver Summary',
    _a[CareInsightTypes.Facility_Summary] = 'Community Summary',
    _a);
export var facilitySlugs = {
    corporateManagement: 'corporate-management',
    facilityManagement: 'facility-management',
    facilityHomePage: 'facilityHomePage',
    facilityDashboard: 'facilityDashboard',
    facilityResident: 'facilityResident',
    residentDashboard: 'seniorID',
    accountID: 'accountID',
    timezone: 'timezone',
    senior: 'senior',
    subRoute: 'subRoute',
};
export var residentSubPages = {
    wellnessData: {
        path: 'wellness-data',
        title: 'Resident Wellness Data',
        value: 'Wellness Data',
    },
    location: {
        path: 'location',
        title: 'Resident Location',
        value: 'Location',
    },
};
export var corpFacilityUserRole = {
    FACA: {
        label: 'Community Agent',
    },
};
export var MONTHS = Object.freeze(Array.from(Array(12).keys()).map(function (key) {
    return new Date(0, key).toLocaleString('en', { month: 'long' }).toLowerCase();
}));
export var reportsTypes = Object.freeze([
    {
        label: 'Emergent Care',
        key: API_RESPONSE_REPORT_TYPES.EmergentCare,
    },
    {
        label: 'Falls and SOS Data Report',
        key: API_RESPONSE_REPORT_TYPES.FallsAndSos,
    },
    {
        label: 'Background Data report',
        key: API_RESPONSE_REPORT_TYPES.BackgroundData,
    },
    {
        label: 'Body Scale',
        key: API_RESPONSE_REPORT_TYPES.BodyScale,
    },
    {
        label: '14 Day Average Sleep Score Report',
        key: API_RESPONSE_REPORT_TYPES.SleepReport,
    },
    {
        label: '14-Day Average Body Health Metrics',
        key: API_RESPONSE_REPORT_TYPES.AverageBodyHealthMetricsReport,
    },
    {
        label: '14 Day Average Watch Activity Report',
        key: API_RESPONSE_REPORT_TYPES.AverageWatchActivityReport,
    },
    {
        label: 'Contact Auth_ID',
        key: API_RESPONSE_REPORT_TYPES.ContactAuthId,
    },
    {
        label: 'Senior Persona',
        key: API_RESPONSE_REPORT_TYPES.SeniorPersona,
    },
    {
        label: 'Wellness Data',
        key: API_RESPONSE_REPORT_TYPES.WellnessData,
    },
    {
        label: 'Wellness Plan',
        key: API_RESPONSE_REPORT_TYPES.WellnessPlan,
    },
    {
        label: 'Wellness(Wellbeing) Survey',
        key: API_RESPONSE_REPORT_TYPES.WellbeingSurvey,
    },
    {
        label: 'Holistic Assessments',
        key: API_RESPONSE_REPORT_TYPES.HolisticAssessments,
    },
    {
        label: 'UCLA Loneliness Assessments',
        key: API_RESPONSE_REPORT_TYPES.UCLALonelinessAssessment,
    },
    {
        label: 'Falls And Hospitalization Assessments',
        key: API_RESPONSE_REPORT_TYPES.FallsAndHospitalizationAssessments,
    },
    {
        label: 'Home Safety Assessments',
        key: API_RESPONSE_REPORT_TYPES.HomeSafetyAssessments,
    },
    {
        label: 'SOS-Fall',
        key: API_RESPONSE_REPORT_TYPES.SOSFall,
    },
    {
        label: 'Medical Conditions',
        key: API_RESPONSE_REPORT_TYPES.MedicalConditions,
    },
    {
        label: 'Medication List',
        key: API_RESPONSE_REPORT_TYPES.MedicationList,
    },
    {
        label: 'Member Questionnaire 1',
        key: API_RESPONSE_REPORT_TYPES.MemberQuestionnaire1,
    },
    {
        label: 'Member Questionnaire 2',
        key: API_RESPONSE_REPORT_TYPES.MemberQuestionnaire2,
    },
    {
        label: 'Member Questionnaire 3',
        key: API_RESPONSE_REPORT_TYPES.MemberQuestionnaire3,
    },
    {
        label: 'Sleep Data',
        key: API_RESPONSE_REPORT_TYPES.SleepData,
    },
    {
        label: 'Watch Activity Report',
        key: API_RESPONSE_REPORT_TYPES.WatchActivityReport,
    },
    {
        label: 'Disable Report',
        key: API_RESPONSE_REPORT_TYPES.DisableReport,
    },
]);
export var corporateOptions = {
    nonResidents: 'Non Residents',
    allCorporates: 'All Corporates',
};
